@import '~modularscale-sass/stylesheets/modularscale';

$modularscale: (
  base: 1em,
  ratio: 1.25
);

body {
  margin: 0;
  min-height: 100vh;
  background: #F8FAFC;
}

.Home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;

  .hello {
    font-size: ms(10);
    font-weight: bold;
    color: #00CC2C;
  }

  .background {
    background: #00CC2C30;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .community-report {
    display: none;

    &.active {
        width: 70%;
        display: block;
    }

    .meta {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .name {
            font-size: ms(6);
            font-weight: bold;
            color: #0F172A;
        }

        .weather {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .icon {
                width: ms(7);
                height: ms(7);
                border-radius: 50%;
                background: #60A5FA;
                cursor: pointer;

                &:hover {
                    background: #2563EB;
                }
            }
        }
    }

    .stats {
        display: flex;
        flex-direction: row;
        margin-top: ms(3);

        label {
            font-size: ms(0);
            color: #94A3B8;
        }

        .household,
        .co2 {
            background: red;
            padding: ms(0);
            margin: ms(0);
            border-radius: ms(-2);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .number {
                font-size: ms(4);
            }

            .description {
                font-weight: 400;
                font-size: ms(1);
            }
        }

        .household {
            background: #DCFCE7;
            color: #16A34A;

            .number {
                color: #166534;
            }
        }

        .co2 {
            background: #DBEAFE;
            color: #2563EB;

            .number {
                color: #1E40AF;
            }
        }

        .all-time {
            .household,
            .co2 {
                margin-right: 0;
                margin-left: 0;
            }
        }

        .today {
            .household,
            .co2 {
                margin-left: 0;
            }
        }
    }
  }
}

.Earth {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #100F2A;

    .background {
        position: absolute;
        bottom: 0;
        width: 100vw;
        bottom: 0;
    }

    .controls {
        position: fixed;
        top: 1rem;
        right: 1rem;
        display: flex;
        flex-direction: column;
        color: #6366F1;
        justify-content: space-between;
        height: 5rem;
        z-index: 999;

        .arrow-up,
        .arrow-down {
            padding: 0.5rem;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            user-select: none;

            &:hover {
                color: #A5B4FC;
                background: #4F46E5;
            }
        }

        .level {
            font-size: 12.80px;
            align-self: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .land {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }

    .artifact {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }
}

.Weight {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .value {
        font-size: 95.37px;
        color: #84CC16;
    }

    .circle {
        background: #ECFCCB;
        min-width: 1rem;
        min-height: 1rem;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
    }

    .unit {
        font-size: 39.06px;
        color: #4D7C0F;
        margin-top: 3rem;
    }

    .progress {
        position: absolute;
    }

    .CircularProgressbar {
        margin: 0;
        margin-top: -0.25rem;
    }

    .CircularProgressbar-path {
      stroke: #D9F99D;
      stroke-width: 3;
    }
    .CircularProgressbar-trail {
      stroke: transparent;
    }
    .CircularProgressbar-text {
      fill: transparent;
    }
    .CircularProgressbar-background {
      fill: transparent;
    }

    .success {
        position: absolute;
        color: #84CC16;
    }
}

.Impact {
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;

    .car,
    .gas,
    .fridge,
    .co2 {
        padding: ms(2);
        width: ms(10);
        height: ms(10);
        font-size: 25.00px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: red;
        text-align: center;
        position: absolute;
    }

    .co2 {
        left: 20rem;
        top: 10rem;
        background: #DBEAFE;
        color: #1E40AF;
    }

    .gas {
        right: 20rem;
        top: 10rem;
        background: #D1FAE5;
        color: #065F46;
    }

    .fridge {
        left: 20rem;
        bottom: 10rem;
        background: #FCE7F3;
        color: #9D174D;
    }

    .car {
        right: 20rem;
        bottom: 10rem;
        background: #FEF3C7;
        color: #92400E;
    }
}

.Controls {
    .up,
    .down,
    .number {
        padding: ms(5);
    }
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('./fonts/CircularStd-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularSpotifyText-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularSpotifyText-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('./fonts/CircularStd-BookItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
  font-family: "Circular Std";
}